import React, { useState, useEffect } from 'react';
import { Button } from '../components/Button';
import { ImageModal } from '../components/ImageModal';
import './Landing.css';
import { Link } from 'react-router-dom';

const Landing = () => {
  const getBrowserLanguage = () => {
    const browserLang = navigator.language.toLowerCase();
    
    const languageMap = {
      'zh-cn': 'zh',
      'zh-tw': 'zh-tw',
      'zh-hk': 'zh-tw',
      'zh': 'zh',
      'en': 'en',
      'en-us': 'en',
      'en-gb': 'en',
      'ja': 'ja',
      'ja-jp': 'ja',
      'ko': 'ko',
      'ko-kr': 'ko',
      'fr': 'fr',
      'fr-fr': 'fr',
      'fr-ca': 'fr',
      'de': 'de',
      'de-de': 'de',
      'es': 'es',
      'es-es': 'es',
      'es-mx': 'es',
      'ar': 'ar',
      'ar-sa': 'ar'
    };

    return languageMap[browserLang] || 'en';
  };

  const [currentLang, setCurrentLang] = useState(getBrowserLanguage());

  useEffect(() => {
    const savedLang = localStorage.getItem('preferredLanguage');
    if (savedLang) {
      setCurrentLang(savedLang);
    }
  }, []);

  const handleLanguageChange = (lang) => {
    setCurrentLang(lang);
    localStorage.setItem('preferredLanguage', lang);
  };

  const [selectedImage, setSelectedImage] = useState(null);
  
  const content = {
    zh: {
      hero: {
        title: "Widgeet - 您的终极小部件工具包",
        subtitle: "已被数百万用户选择的安卓小部件应用",
        rating: "Google Play 评分 4.8 星",
        cta: "立即下载",
        playStore: "在 Google Play 上获取",
        playStoreBadge: "/images/badge/GetItOnGooglePlay_Badge_Web_color_Chinese-CN.png"
      },
      features: {
        title: "主要功能",
        items: [
          {
            icon: "🎵",
            title: "音乐播放器小部件",
            desc: "支持 Spotify、YouTube Music 等多平台控制，提供经典 iPod、现代简约等多种风格"
          },
          {
            icon: "⏰",
            title: "时钟小部件",
            desc: "多种精美时钟样式，支持模拟和翻页时钟，可自定义颜色和布局"
          },
          {
            icon: "📅",
            title: "日历小部件",
            desc: "优雅的设计，让您的日程触手可及，完美集成时钟功能"
          },
          {
            icon: "📊",
            title: "设备信息小部件",
            desc: "实时监控手机性能，包应用使用时间、存储和内存状态"
          },
          {
            icon: "🖼️",
            title: "照片小部件",
            desc: "展示您喜爱的照片，支持自定义幻灯片放映"
          }
        ]
      },
      advantages: {
        title: "为什么选择 Widgeet",
        items: [
          {
            icon: "🎨",
            title: "简单易用",
            desc: "一键添加小部件，支持任意尺寸调整"
          },
          {
            icon: "🔋",
            title: "超低功耗",
            desc: "优化的性能设计，不影响电池续航"
          },
          {
            icon: "🎯",
            title: "持续更新",
            desc: "定期添加新功能和小部件样式"
          }
        ]
      },
      footer: {
        privacy: "隐私政策"
      }
    },
    en: {
      hero: {
        title: "Widgeet - Your Ultimate Widget Toolkit",
        subtitle: "Android widget app trusted by millions of users",
        rating: "4.8 stars on Google Play",
        cta: "Download Now",
        playStore: "Get it on Google Play",
        playStoreBadge: "/images/badge/GetItOnGooglePlay_Badge_Web_color_English.png"
      },
      features: {
        title: "Features",
        items: [
          {
            icon: "🎵",
            title: "Music Player Widget",
            desc: "Control Spotify, YouTube Music and more, with classic iPod, modern minimalist styles"
          },
          {
            icon: "⏰",
            title: "Clock Widget",
            desc: "Beautiful analog and flip clock styles, customizable colors and layouts"
          },
          {
            icon: "📅",
            title: "Calendar Widget",
            desc: "Elegant design keeps your schedule at hand, with integrated clock functionality"
          },
          {
            icon: "📊",
            title: "Device Info Widget",
            desc: "Real-time monitoring of phone performance, including app usage time, storage, and memory status"
          },
          {
            icon: "🖼️",
            title: "Photo Widget",
            desc: "Display your favorite photos, with customizable slideshow"
          }
        ]
      },
      advantages: {
        title: "Why Choose Widgeet",
        items: [
          {
            icon: "🎨",
            title: "Easy to Use",
            desc: "One-click widget addition, supports any size adjustment"
          },
          {
            icon: "🔋",
            title: "Low Power Consumption",
            desc: "Optimized performance design, no impact on battery life"
          },
          {
            icon: "🎯",
            title: "Regular Updates",
            desc: "Regularly adding new features and widget styles"
          }
        ]
      },
      footer: {
        privacy: "Privacy Policy"
      }
    },
    ja: {
      hero: {
        title: "Widgeet - 究極のウィジェットツールキット",
        subtitle: "数百万人のユーザーに信頼されているAndroidウィジェットアプリ",
        cta: "今すぐダウンロード",
        playStore: "Google Play で手に入れよう",
        playStoreBadge: "/images/badge/GetItOnGooglePlay_Badge_Web_color_Japanese.png",
        rating: "Google Play で 4.8 星"
      },
      features: {
        title: "主な機能",
        items: [
          {
            icon: "🎵",
            title: "音楽プレーヤーウィジェット",
            desc: "Spotify、YouTube Musicなどの操作に対応、クラシックiPodスタイルやモダンなデザイン"
          },
          {
            icon: "⏰",
            title: "時計小部件",
            desc: "美しいアナログとフリップ時計スタイル、カスタマイズ可能な色とレアト"
          },
          {
            icon: "📅",
            title: "カレンダー小部件",
            desc: "エレガントなデザインで、あなたのスケジュールを手の届く範囲にする"
          },
          {
            icon: "📊",
            title: "デバイス情報小部件",
            desc: "スマートフォンのパフォーマンスをリアルタイムで監視し、アプリ使用時間、ストレージ、メモリー状態を含む"
          },
          {
            icon: "🖼️",
            title: "写真小部件",
            desc: "あなたのお気に入りの写真を表示し、カスタマイズ可能なスライドショーをサポート"
          }
        ]
      },
      advantages: {
        title: "Widgeetを選ぶ理由",
        items: [
          {
            icon: "🎨",
            title: "簡単に使用できる",
            desc: "1クリックで小部件を追加し、任意のサイズに調整できる"
          },
          {
            icon: "🔋",
            title: "低消費電力",
            desc: "最適化されたパフォーマンスデザイン、バッテリーの持続時間に影響を与えない"
          },
          {
            icon: "🎯",
            title: "定期的な更新",
            desc: "新機能と小部件スタイルを定期的に追加する"
          }
        ]
      },
      footer: {
        privacy: "プライバシーポリシー"
      }
    },
    ko: {
      hero: {
        title: "Widgeet - 궁극의 위젯 툴킷",
        subtitle: "수백만 명의 사용자가 선택한 안드로이드 위젯 앱",
        cta: "지금 다운로드",
        playStore: "Google Play에서 다운로드",
        playStoreBadge: "/images/badge/GetItOnGooglePlay_Badge_Web_color_Korean.png",
        rating: "Google Play 평점 4.8점"
      },
      features: {
        title: "주요 기능",
        items: [
          {
            icon: "🎵",
            title: "음악 플레이어 위젯",
            desc: "Spotify, YouTube Music 등을 지원하며, 클래식 iPod 스타일과 모던한 디자인 제공"
          },
          {
            icon: "⏰",
            title: "시계 위젯",
            desc: "아날로그와 플립 시계 스타일, 커스터마이징 가능한 색상과 레이아웃"
          },
          {
            icon: "📅",
            title: "캘린더 위젯",
            desc: "우아한 디자인으로 일정을 한눈에, 시계 기능과 완벽게 통합"
          },
          {
            icon: "📊",
            title: "기기 정보 위젯",
            desc: "앱 사용 시간, 저장소, 메모리 상태 등 실시간 성능 모니터링"
          },
          {
            icon: "🖼️",
            title: "사진 위젯",
            desc: "좋아하는 사진을 표시하고 맞춤형 슬라이드쇼 지원"
          }
        ]
      },
      advantages: {
        title: "Widgeet을 선택하는 이유",
        items: [
          {
            icon: "🎨",
            title: "사용하기 쉬움",
            desc: "원클릭으로 위젯 추가, 자유로운 크기 조절 지원"
          },
          {
            icon: "🔋",
            title: "저전력 설계",
            desc: "최적화된 성능으로 배터리 수명에 영향 없음"
          },
          {
            icon: "🎯",
            title: "정기 업데이트",
            desc: "새로운 기능과 위젯 스타일 지속 추가"
          }
        ]
      },
      footer: {
        privacy: "개인정보 처리방침"
      }
    },
    fr: {
      hero: {
        title: "Widgeet - Votre Boîte à Outils de Widgets Ultime",
        subtitle: "Application de widgets Android approuvée par des millions d'utilisateurs",
        cta: "Télécharger maintenant",
        playStore: "Disponible sur Google Play",
        playStoreBadge: "/images/badge/GetItOnGooglePlay_Badge_Web_color_French.png",
        rating: "4.8 étoiles sur Google Play"
      },
      features: {
        title: "Fonctionnalités",
        items: [
          {
            icon: "🎵",
            title: "Widget Lecteur de Musique",
            desc: "Contrôlez Spotify, YouTube Music et plus encore, avec des styles classiques iPod et minimalistes modernes"
          },
          {
            icon: "⏰",
            title: "Widget Horloge",
            desc: "Superbes styles d'horloge analogique et à feuillets, couleurs et dispositions personnalisables"
          },
          {
            icon: "📅",
            title: "Widget Calendrier",
            desc: "Un design élégant garde votre agenda à portée de main, avec fonctionnalité d'horloge intégrée"
          },
          {
            icon: "📊",
            title: "Widget Info Appareil",
            desc: "Surveillance en temps réel des performances du téléphone, y compris le temps d'utilisation, le stockage et l'état de la mémoire"
          },
          {
            icon: "🖼️",
            title: "Widget Photo",
            desc: "Affichez vos photos préférées avec un diaporama personnalisable"
          }
        ]
      },
      advantages: {
        title: "Pourquoi Choisir Widgeet",
        items: [
          {
            icon: "🎨",
            title: "Facile à Utiliser",
            desc: "Ajout de widgets en un clic, supporte tout ajustement de taille"
          },
          {
            icon: "🔋",
            title: "Faible Consommation",
            desc: "Conception optimisée, sans impact sur la durée de vie de la batterie"
          },
          {
            icon: "🎯",
            title: "Mises à Jour Régulières",
            desc: "Ajout régulier de nouvelles fonctionnalités et styles de widgets"
          }
        ]
      },
      footer: {
        privacy: "Politique de confidentialité"
      }
    },
    de: {
      hero: {
        title: "Widgeet - Ihr ultimatives Widget-Toolkit",
        subtitle: "Android-Widget-App, der Millionen Nutzer vertrauen",
        cta: "Jetzt herunterladen",
        playStore: "Jetzt bei Google Play",
        playStoreBadge: "/images/badge/GetItOnGooglePlay_Badge_Web_color_German.png",
        rating: "4.8 Sterne bei Google Play"
      },
      features: {
        title: "Funktionen",
        items: [
          {
            icon: "🎵",
            title: "Musik-Player Widget",
            desc: "Steuern Sie Spotify, YouTube Music und mehr, mit klassischem iPod-Stil und modernem minimalistischem Design"
          },
          {
            icon: "⏰",
            title: "Uhr-Widget",
            desc: "Schöne analoge und Flip-Uhr-Stile, anpassbare Farben und Layouts"
          },
          {
            icon: "📅",
            title: "Kalender-Widget",
            desc: "Elegantes Design hält Ihren Zeitplan griffbereit, mit integrierter Uhrenfunktion"
          },
          {
            icon: "📊",
            title: "Geräteinfo-Widget",
            desc: "Echtzeitüberwachung der Telefonleistung, einschließlich App-Nutzungszeit, Speicher und Speicherstatus"
          },
          {
            icon: "🖼️",
            title: "Foto-Widget",
            desc: "Zeigen Sie Ihre Lieblingsfotos mit anpassbarer Diashow"
          }
        ]
      },
      advantages: {
        title: "Warum Widgeet wählen",
        items: [
          {
            icon: "🎨",
            title: "Einfach zu Bedienen",
            desc: "Widget-Hinzufügung mit einem Klick, unterstützt jede Größenanpassung"
          },
          {
            icon: "🔋",
            title: "Niedriger Stromverbrauch",
            desc: "Optimiertes Design ohne Einfluss auf die Akkulaufzeit"
          },
          {
            icon: "🎯",
            title: "Regelmäßige Updates",
            desc: "Regelmäßige Ergänzung neuer Funktionen und Widget-Stile"
          }
        ]
      },
      footer: {
        privacy: "Datenschutzrichtlinie"
      }
    },
    'zh-tw': {
      hero: {
        title: "Widgeet - 您的終極小工具工具",
        subtitle: "已被數百萬用戶選擇的安卓小工具應用",
        cta: "立即下載",
        playStore: "在 Google Play 上取得",
        playStoreBadge: "/images/badge/GetItOnGooglePlay_Badge_Web_color_Chinese-TW.png",
        rating: "Google Play 評分 4.8 星"
      },
      features: {
        title: "主要功能",
        items: [
          {
            icon: "🎵",
            title: "音樂播放器小工具",
            desc: "支援 Spotify、YouTube Music 等多平台控制，提供經典 iPod、現代簡約等多種風格"
          },
          {
            icon: "⏰",
            title: "時鐘小工具",
            desc: "多種精美時鐘樣式，支援類比和翻頁時鐘，可自訂顏色和版面"
          },
          {
            icon: "📅",
            title: "日曆小工具",
            desc: "優雅的設計，讓您的日程觸手可及，完美整合時鐘功能"
          },
          {
            icon: "📊",
            title: "裝置資訊小工具",
            desc: "即時監控手機效能，包括應用程式使用時間、儲存空間和記憶狀態"
          },
          {
            icon: "🖼️",
            title: "相片小工具",
            desc: "展示您喜愛的相片，支援自訂投影片放映"
          }
        ]
      },
      advantages: {
        title: "為什麼選擇 Widgeet",
        items: [
          {
            icon: "🎨",
            title: "簡單易用",
            desc: "一鍵新增小工具，支援任意尺寸調整"
          },
          {
            icon: "🔋",
            title: "超低耗電",
            desc: "最佳化的效能設計，不影響電池續航力"
          },
          {
            icon: "🎯",
            title: "持續更新",
            desc: "定期新增功能和小工具樣式"
          }
        ]
      },
      footer: {
        privacy: "隱私權政策"
      }
    },
    es: {
      hero: {
        title: "Widgeet - Tu Kit de Widgets Definitivo",
        subtitle: "Aplicación de widgets para Android con millones de usuarios",
        cta: "Descargar ahora",
        playStore: "Disponible en Google Play",
        playStoreBadge: "/images/badge/GetItOnGooglePlay_Badge_Web_color_Spanish.png",
        rating: "4.8 estrellas en Google Play"
      },
      features: {
        title: "Características",
        items: [
          {
            icon: "🎵",
            title: "Widget de Reproductor de Música",
            desc: "Controla Spotify, YouTube Music y más, con estilos clásicos de iPod y minimalistas modernos"
          },
          {
            icon: "⏰",
            title: "Widget de Reloj",
            desc: "Hermosos estilos de reloj analógico y flip, colores y diseños personalizables"
          },
          {
            icon: "📅",
            title: "Widget de Calendario",
            desc: "Diseño elegante mantiene tu agenda al alcance, con funcionalidad de reloj integrada"
          },
          {
            icon: "📊",
            title: "Widget de Información del Dispositivo",
            desc: "Monitoreo en tiempo real del rendimiento del teléfono, incluyendo tiempo de uso, almacenamiento y estado de memoria"
          },
          {
            icon: "🖼️",
            title: "Widget de Fotos",
            desc: "Muestra tus fotos favoritas con presentación personalizable"
          }
        ]
      },
      advantages: {
        title: "Por Qué Elegir Widgeet",
        items: [
          {
            icon: "🎨",
            title: "Fácil de Usar",
            desc: "Añade widgets con un clic, soporta cualquier ajuste de tamaño"
          },
          {
            icon: "🔋",
            title: "Bajo Consumo",
            desc: "Diseño optimizado sin impacto en la duración de la batería"
          },
          {
            icon: "🎯",
            title: "Actualizaciones Regulares",
            desc: "Agregando regularmente nuevas funciones y estilos de widgets"
          }
        ]
      },
      footer: {
        privacy: "Política de Privacidad"
      }
    },
    ar: {
      hero: {
        title: "Widgeet - مجموعة أدوات الويدجت النهائية",
        subtitle: "تطبيق ويدجت أندرويد يثق به الملايين من المستخدمين",
        cta: "تحميل الآن",
        playStore: "متوفر على جوجل بلاي",
        playStoreBadge: "/images/badge/GetItOnGooglePlay_Badge_Web_color_Arabic.png",
        rating: "4.8 نجوم على Google Play"
      },
      features: {
        title: "المميزات",
        items: [
          {
            icon: "🎵",
            title: "ويدجت مشغل الموسيقى",
            desc: "تحكم في Spotify وYouTube Music والمزيد، مع أنماط iPod الكلاسيكية والتصاميم العصرية البسيطة"
          },
          {
            icon: "⏰",
            title: "ويدجت الساعة",
            desc: "تصاميم جميلة للساعة التناظرية والرقمية، مع ألوان وتخطيطات قابلة للتخصيص"
          },
          {
            icon: "📅",
            title: "ويدجت التقويم",
            desc: "تصميم أنيق يبقي جدولك في متناول يدك، مع وظائف الساعة المدمجة"
          },
          {
            icon: "📊",
            title: "ويدجت معلومات الجهاز",
            desc: "مراقبة أداء الهاتف في الوقت الفعلي، بما في ذلك وقت استخدام التطبيق والتخزين وحالة الذاكرة"
          },
          {
            icon: "🖼️",
            title: "ويدجت الصور",
            desc: "اعرض صورك المفضلة مع عرض شرائح قابل للتخصيص"
          }
        ]
      },
      advantages: {
        title: "لماذا تختار Widgeet",
        items: [
          {
            icon: "🎨",
            title: "سهل الاستخدام",
            desc: "إضافة الويدجت بنقرة واحدة، يدعم أي تعديل للحجم"
          },
          {
            icon: "🔋",
            title: "استهلاك منخفض للطاقة",
            desc: "تصميم محسن بدون تأثير على عمر البطارية"
          },
          {
            icon: "🎯",
            title: "تحديثات منتظمة",
            desc: "إضافة ميزات جديدة وأنماط ويدجت بشكل منتظم"
          }
        ]
      },
      footer: {
        privacy: "سياسة الخصوصية"
      }
    }
  };

  const screenshots = [
    "/screenshots/1.png",
    "/screenshots/2.png",
    "/screenshots/3.png",
    "/screenshots/4.png",
    "/screenshots/5.png"
  ];

  const t = content[currentLang];

  const languageOptions = [
    { code: 'en', label: 'English' },
    { code: 'es', label: 'Español' },
    { code: 'zh', label: '简体中文' },
    { code: 'zh-tw', label: '繁體中文' },
    { code: 'ja', label: '日本語' },
    { code: 'ko', label: '한국어' },
    { code: 'fr', label: 'Français' },
    { code: 'de', label: 'Deutsch' },
    { code: 'ar', label: 'العربية' }
  ];

  return (
    <main className="landing">
      <header className="language-switcher">
        <select 
          value={currentLang} 
          onChange={(e) => handleLanguageChange(e.target.value)}
          className="language-select"
        >
          {languageOptions.map(({ code, label }) => (
            <option key={code} value={code}>
              {label}
            </option>
          ))}
        </select>
      </header>

      <article className="hero">
        <div className="logo-container">
          <img 
            src="/images/logo.png"
            alt="Widgeet Logo" 
            className="app-logo"
          />
        </div>
        <h1>{t.hero.title}</h1>
        <p className="hero-subtitle">
          {t.hero.subtitle}
        </p>
        <p className="hero-rating">
          ⭐️ {t.hero.rating}
        </p>
        <div className="cta-buttons">
          <a 
            href="https://play.google.com/store/apps/details?id=art.widgeet.android" 
            target="_blank" 
            rel="noopener noreferrer"
            className="play-store-link"
          >
            <img 
              src={t.hero.playStoreBadge}
              alt="Get it on Google Play"
              className="play-store-badge"
            />
          </a>
        </div>
      </article>

      <section className="screenshots">
        <div className="screenshots-container">
          {screenshots.map((src, index) => (
            <div 
              key={index} 
              className="screenshot-item"
              onClick={() => setSelectedImage(src)}
            >
              <img 
                src={src} 
                alt={`Screenshot ${index + 1}`}
                className="screenshot-image"
                loading="lazy"
              />
            </div>
          ))}
        </div>
      </section>

      <article className="features">
        <h2>{t.features.title}</h2>
        <div className="features-grid">
          {t.features.items.map((feature, index) => (
            <div key={index} className="feature-card">
              <div className="feature-icon">{feature.icon}</div>
              <h3>{feature.title}</h3>
              <p>{feature.desc}</p>
            </div>
          ))}
        </div>
      </article>

      <article className="advantages">
        <h2>{t.advantages.title}</h2>
        <div className="advantages-grid">
          {t.advantages.items.map((item, index) => (
            <div key={index} className="advantage-card">
              <div className="advantage-icon">{item.icon}</div>
              <h3>{item.title}</h3>
              <p>{item.desc}</p>
            </div>
          ))}
        </div>
      </article>

      {selectedImage && (
        <ImageModal
          src={selectedImage}
          alt="Screenshot Preview"
          onClose={() => setSelectedImage(null)}
          screenshots={screenshots}
        />
      )}

      <footer className="footer">
        <Link to="/privacy" className="privacy-link">{t.footer.privacy}</Link>
      </footer>

      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "MobileApplication",
          "name": "Widgeet",
          "applicationCategory": "UtilitiesApplication",
          "operatingSystem": "Android",
          "offers": {
            "@type": "Offer",
            "price": "0",
            "priceCurrency": "USD"
          },
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.8",
            "ratingCount": "1000000"
          }
        })}
      </script>
    </main>
  );
};

export default Landing; 