import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './ImageModal.css';

export const ImageModal = ({ src, alt, onClose, screenshots }) => {
  const initialSlide = screenshots.indexOf(src);

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>&times;</button>
        <Swiper
          modules={[Navigation, Pagination]}
          navigation
          pagination={{ clickable: true }}
          initialSlide={initialSlide}
          loop={true}
          className="modal-swiper"
        >
          {screenshots.map((screenshot, index) => (
            <SwiperSlide key={index}>
              <img src={screenshot} alt={`Screenshot ${index + 1}`} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}; 