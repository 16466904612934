import React from 'react';
import { Link } from 'react-router-dom';
import './Privacy.css';

const Privacy = () => {
  // 从 localStorage 获取语言设置，如果没有则使用浏览器语言
  const getBrowserLanguage = () => {
    const savedLang = localStorage.getItem('preferredLanguage');
    if (savedLang) return savedLang;

    const browserLang = navigator.language.toLowerCase();
    const languageMap = {
      'zh-cn': 'zh',
      'zh-tw': 'zh-tw',
      'zh': 'zh',
      'en': 'en',
      'en-us': 'en',
      'en-gb': 'en',
      'ja': 'ja',
      'ja-jp': 'ja',
      'ko': 'ko',
      'ko-kr': 'ko',
      'fr': 'fr',
      'fr-fr': 'fr',
      'de': 'de',
      'de-de': 'de',
      'es': 'es',
      'es-es': 'es',
      'ar': 'ar'
    };

    return languageMap[browserLang] || 'en';
  };

  const content = {
    en: {
      title: "Privacy Policy",
      intro: "Thank you for using our application Widgeet (hereinafter referred to as \"the Service\"). In order to protect your privacy and personal information, we have formulated the following privacy policy to inform you about how we collect, use, share, and protect your information.",
      sections: [
        {
          title: "1. Information Collection",
          content: "We collect the following information through Firebase:",
          items: [
            "Device ID: We collect your device ID to identify and analyze issues;",
            "Crash Logs: We collect crash logs to improve the stability of the service;",
            "Performance Information: We collect performance information to evaluate and optimize the user experience."
          ],
          additional: "These pieces of information are collected and stored by Firebase, and during the uploading process, they are encrypted. We do not store these pieces of information ourselves. Your personal data is stored anonymously, which means we cannot identify you personally."
        },
        {
          title: "2. Information Usage",
          content: "The collected information will be used for the following purposes:",
          items: [
            "Identify and analyze issues to improve the stability of the service;",
            "Evaluate application performance to optimize the user experience."
          ]
        },
        {
          title: "3. Information Sharing",
          content: "We do not share your information with any third parties."
        },
        {
          title: "4. Changes to This Privacy Policy",
          content: "We may update this privacy policy from time to time to reflect changes in our services and privacy practices. Any changes will be published on this page, and the new version will take effect immediately."
        }
      ],
      conclusion: "We value your privacy and appreciate your trust and support.",
      backToHome: "Back to Home"
    },
    zh: {
      title: "隐私政策",
      intro: "感谢您使用我们的应用程序 Widgeet（以下简称\"服务\"）。为了保护您的隐私和个人信息，我们制定了以下隐私政策，以告知您我们如何收集、使用、共享和保护您的信息。",
      sections: [
        {
          title: "1. 信息收集",
          content: "我们通过 Firebase 收集以下信息：",
          items: [
            "设备 ID：我们收集您的设备 ID 以识别和分析问题；",
            "崩溃日志：我们收集崩溃日志以提高服务的稳定性；",
            "性能信息：我们收集性能信息以评估和优化用户体验。"
          ],
          additional: "这些信息由 Firebase 收集和存储，在上传过程中会进行加密。我们不会自行存储这些信息。您的个人数据以匿名方式存储，这意味着我们无法识别您的个人身份。"
        },
        {
          title: "2. 信息使用",
          content: "收集的信息将用于以下目的：",
          items: [
            "识别和分析问题以提高服务的稳定性；",
            "评估应用程序性能以优化用户体验。"
          ]
        },
        {
          title: "3. 信息共享",
          content: "我们不会与任何第三方共享您的信息。"
        },
        {
          title: "4. 隐私政策的变更",
          content: "我们可能会不时更新此隐私政策，以反映我们服务和隐私实践的变化。任何变更都将在此页面上发布，新版本将立即生效。"
        }
      ],
      conclusion: "我们重视您的隐私，感谢您的信任和支持。",
      backToHome: "返回首页"
    },
    'zh-tw': {
      title: "隱私權政策",
      intro: "感謝您使用我們的應用程式 Widgeet（以下簡稱\"服務\"）。為了保護您的隱私和個人資訊，我們制定了以下隱私權政策，以告知您我們如何收集、使用、共享和保護您的資訊。",
      sections: [
        {
          title: "1. 資訊收集",
          content: "我們通過 Firebase 收集以下資訊：",
          items: [
            "裝置 ID：我們收集您的裝置 ID 以識別和分析問題；",
            "當機日誌：我們收集當機日誌以提高服務的穩定性；",
            "效能資訊：我們收集效能資訊以評估和優化使用者體驗。"
          ],
          additional: "這些資訊由 Firebase 收集和儲存，在上傳過程中會進行加密。我們不會自行儲存這些資訊。您的個人資料以匿名方式儲存，這意味著我們無法識別您的個人身份。"
        },
        {
          title: "2. 資訊使用",
          content: "收集的資訊將用於以下目的：",
          items: [
            "識別和分析問題以提高服務的穩定性；",
            "評估應用程式效能以優化使用者體驗。"
          ]
        },
        {
          title: "3. 資訊共享",
          content: "我們不會與任何第三方共享您的資訊。"
        },
        {
          title: "4. 隱私權政策的變更",
          content: "我們可能會不時更新此隱私權政策，以反映我們服務和隱私實踐的變化。任何變更都將在此頁面上發布，新版本將立即生效。"
        }
      ],
      conclusion: "我們重視您的隱私，感謝您的信任和支持。",
      backToHome: "返回首頁"
    },
    ja: {
      title: "プライバシーポリシー",
      intro: "Widgeet（以下「本サービス」）をご利用いただき、ありがとうございます。当社は、お客様のプライバシーと個人情報を保護するため、以下のプライバシーポリシーを定め、お客様の情報の収集、使用、共有、保護の方法についてお知らせいたします。",
      sections: [
        {
          title: "1. 情報収集",
          content: "当社は、Firebase を通じて以下の情報を収集します：",
          items: [
            "デバイスID：問題の特定と分析のためにデバイスIDを収集します；",
            "クラッシュログ：サービスの安定性向上のためにクラッシュログを収集します；",
            "パフォーマンス情報：ユーザー体験の評価と最適化のためにパフォーマンス情報を収集します。"
          ],
          additional: "これらの情報は Firebase によって収集・保存され、アップロード時に暗号化されます。当社自身でこれらの情報存することはありません。お客様の個人データは匿名で保存され、当社がお客様個人を特定することはできません。"
        },
        {
          title: "2. 情報使用",
          content: "収集した情報は以下の目的で使用されます：",
          items: [
            "問題の特定と分析によるサービスの安定性向上；",
            "ユーザー体験の評価と最適化によるユーザー体験の向上。"
          ]
        },
        {
          title: "3. 情報共有",
          content: "当社はお客様の情報を第三者と共有することはありません。"
        },
        {
          title: "4. プライバシーポリシーの変更",
          content: "当社は、サービスとプライバシー実践の変更を反映するため、このプライバシーポリシーを時々更新する場合があります。変更はこのページに掲載され、新しいバージョンは即時に有効になります。"
        }
      ],
      conclusion: "当社はお客様のプライバシーを重視し、ご信頼とご支援に感謝いたします。",
      backToHome: "ホームに戻る"
    },
    ko: {
      title: "개인정보 처리방침",
      intro: "Widgeet(이하 \"서비스\") 애플리케이션을 이용해 주셔서 감사합니다. 귀하의 개인정보를 보호하기 위해 다음과 같은 개인정보 처리방침을 수립하여 정보 수집, 사용, 공유 및 보호 방법을 안내해 드립니다.",
      sections: [
        {
          title: "1. 정보 수집",
          content: "당사는 Firebase를 통해 다음 정보를 수집합니다:",
          items: [
            "기기 ID: 문제 식별 및 분석을 위해 기기 ID를 수집합니다;",
            "충돌 로그: 서비스 안정성 향상을 위해 충돌 로그를 수집합니다;",
            "성능 정보: 사용자 경험 평가 및 최적화를 위해 성능 정보를 수집합니다."
          ],
          additional: "이 정보는 Firebase에 의해 수집 및 저장되며, 업로드 과정에서 암호화됩니다. 당사는 이 정보를 직접 저장하지 않습니다. 귀하의 개인정보는 익명으로 저장되어 당사가 개인을 식별할 수 없습니다."
        },
        {
          title: "2. 정보 사용",
          content: "수집된 정보는 다음 목적으로 사용됩니다:",
          items: [
            "서비스 안정성 향상을 위한 문제 식별 및 분석;",
            "사용자 경험 최적화를 위한 애플리케이션 성능 평가."
          ]
        },
        {
          title: "3. 정보 공유",
          content: "당사는 귀하의 정보를 제3자와 공유하지 않습니다."
        },
        {
          title: "4. 개인정보 처리방침 변경",
          content: "당사는 서비스 및 개인정보 보호 관행의 변경사항을 반영하기 위해 본 개인정보 처리방침을 수시로 업데이트할 수 있습니다. 모든 변경사항은 이 페이지에 게시되며, 새 버전은 즉시 적용됩니다."
        }
      ],
      conclusion: "당사는 귀하의 개인정보를 소중히 여기며, 신뢰와 지원에 감사드립니다.",
      backToHome: "홈으로 돌아가기"
    },
    es: {
      title: "Política de Privacidad",
      intro: "Gracias por utilizar nuestra aplicación Widgeet (en adelante, \"el Servicio\"). Para proteger su privacidad e información personal, hemos formulado la siguiente política de privacidad para informarle sobre cómo recopilamos, utilizamos, compartimos y protegemos su información.",
      sections: [
        {
          title: "1. Recopilación de Información",
          content: "Recopilamos la siguiente información a través de Firebase:",
          items: [
            "ID del dispositivo: Recopilamos su ID de dispositivo para identificar y analizar problemas;",
            "Registros de fallos: Recopilamos registros de fallos para mejorar la estabilidad del servicio;",
            "Información de rendimiento: Recopilamos información de rendimiento para evaluar y optimizar la experiencia del usuario."
          ],
          additional: "Esta información es recopilada y almacenada por Firebase, y durante el proceso de carga, está encriptada. No almacenamos esta información nosotros mismos. Sus datos personales se almacenan de forma anónima, lo que significa que no podemos identificarle personalmente."
        },
        {
          title: "2. Uso de la Información",
          content: "La información recopilada se utilizará para los siguientes propósitos:",
          items: [
            "Identificar y analizar problemas para mejorar la estabilidad del servicio;",
            "Evaluar el rendimiento de la aplicación para optimizar la experiencia del usuario."
          ]
        },
        {
          title: "3. Compartir Información",
          content: "No compartimos su información con terceros."
        },
        {
          title: "4. Cambios en esta Política de Privacidad",
          content: "Podemos actualizar esta política de privacidad ocasionalmente para reflejar cambios en nuestros servicios y prácticas de privacidad. Cualquier cambio será publicado en esta página, y la nueva versión entrará en vigor inmediatamente."
        }
      ],
      conclusion: "Valoramos su privacidad y agradecemos su confianza y apoyo.",
      backToHome: "Volver al Inicio"
    },
    fr: {
      title: "Politique de Confidentialité",
      intro: "Merci d'utiliser notre application Widgeet (ci-après dénommée \"le Service\"). Afin de protéger votre vie privée et vos informations personnelles, nous avons formulé la politique de confidentialité suivante pour vous informer de la manière dont nous collectons, utilisons, partageons et protégeons vos informations.",
      sections: [
        {
          title: "1. Collecte d'Informations",
          content: "Nous collectons les informations suivantes via Firebase :",
          items: [
            "ID de l'appareil : Nous collectons votre ID d'appareil pour identifier et analyser les problèmes ;",
            "Journaux de plantage : Nous collectons les journaux de plantage pour améliorer la stabilité du service ;",
            "Informations de performance : Nous collectons des informations de performance pour évaluer et optimiser l'expérience utilisateur."
          ],
          additional: "Ces informations sont collectées et stockées par Firebase, et sont cryptées pendant le processus de téléchargement. Nous ne stockons pas ces informations nous-mêmes. Vos données personnelles sont stockées de manière anonyme, ce qui signifie que nous ne pouvons pas vous identifier personnellement."
        },
        {
          title: "2. Utilisation des Informations",
          content: "Les informations collectées seront utilisées aux fins suivantes :",
          items: [
            "Identifier et analyser les problèmes pour améliorer la stabilité du service ;",
            "Évaluer les performances de l'application pour optimiser l'expérience utilisateur."
          ]
        },
        {
          title: "3. Partage d'Informations",
          content: "Nous ne partageons pas vos informations avec des tiers."
        },
        {
          title: "4. Modifications de cette Politique de Confidentialité",
          content: "Nous pouvons mettre à jour cette politique de confidentialité de temps à autre pour refléter les changements dans nos services et pratiques de confidentialité. Tout changement sera publié sur cette page, et la nouvelle version prendra effet immédiatement."
        }
      ],
      conclusion: "Nous accordons une grande importance à votre vie privée et apprécions votre confiance et votre soutien.",
      backToHome: "Retour à l'Accueil"
    },
    de: {
      title: "Datenschutzerklärung",
      intro: "Vielen Dank für die Nutzung unserer Anwendung Widgeet (nachfolgend \"der Dienst\" genannt). Um Ihre Privatsphäre und persönlichen Daten zu schützen, haben wir die folgende Datenschutzerklärung formuliert, um Sie darüber zu informieren, wie wir Ihre Informationen sammeln, verwenden, teilen und schützen.",
      sections: [
        {
          title: "1. Informationserfassung",
          content: "Wir erfassen die folgenden Informationen über Firebase:",
          items: [
            "Geräte-ID: Wir erfassen Ihre Geräte-ID zur Identifizierung und Analyse von Problemen;",
            "Absturzprotokolle: Wir sammeln Absturzprotokolle, um die Stabilität des Dienstes zu verbessern;",
            "Leistungsinformationen: Wir sammeln Leistungsinformationen zur Bewertung und Optimierung der Benutzererfahrung."
          ],
          additional: "Diese Informationen werden von Firebase erfasst und gespeichert und während des Uploads verschlüsselt. Wir speichern diese Informationen nicht selbst. Ihre persönlichen Daten werden anonym gespeichert, was bedeutet, dass wir Sie nicht persönlich identifizieren können."
        },
        {
          title: "2. Verwendung der Informationen",
          content: "Die gesammelten Informationen werden für folgende Zwecke verwendet:",
          items: [
            "Identifizierung und Analyse von Problemen zur Verbesserung der Dienststabilität;",
            "Bewertung der Anwendungsleistung zur Optimierung der Benutzererfahrung."
          ]
        },
        {
          title: "3. Informationsweitergabe",
          content: "Wir geben Ihre Informationen nicht an Dritte weiter."
        },
        {
          title: "4. Änderungen dieser Datenschutzerklärung",
          content: "Wir können diese Datenschutzerklärung von Zeit zu Zeit aktualisieren, um Änderungen in unseren Diensten und Datenschutzpraktiken widerzuspiegeln. Alle Änderungen werden auf dieser Seite veröffentlicht und die neue Version tritt sofort in Kraft."
        }
      ],
      conclusion: "Wir schätzen Ihre Privatsphäre und danken für Ihr Vertrauen und Ihre Unterstützung.",
      backToHome: "Zurück zur Startseite"
    },
    ar: {
      title: "سياسة الخصوصية",
      intro: "شكراً لاستخدامك تطبيقنا Widgeet (المشار إليه فيما يلي باسم \"الخدمة\"). من أجل حماية خصوصيتك ومعلوماتك الشخصية، قمنا بصياغة سياسة الخصوصية التالية لإعلامك بكيفية جمع واستخدام ومشاركة وحماية معلوماتك.",
      sections: [
        {
          title: "1. جمع المعلومات",
          content: "نقوم بجمع المعلومات التالية من خلال Firebase:",
          items: [
            "معرف الجهاز: نقوم بجمع معرف جهازك لتحديد وتحليل المشكلات؛",
            "سجلات الأعطال: نقوم بجمع سجلات الأعطال لتحسين استقرار الخدمة؛",
            "معلومات الأداء: نقوم بجمع معلومات الأداء لتقييم وتحسين تجربة المستخدم."
          ],
          additional: "يتم جمع وتخزين هذه المعلومات بواسطة Firebase، ويتم تشفيرها أثناء عملية التحميل. نحن لا نقوم بتخزين هذه المعلومات بأنفسنا. يتم تخزين بياناتك الشخصية بشكل مجهول، مما يعني أننا لا نستطيع تحديد هويتك شخصياً."
        },
        {
          title: "2. استخدام المعلومات",
          content: "سيتم استخدام المعلومات المجمعة للأغراض التالية:",
          items: [
            "تحديد وتحليل المشكلات لتحسين استقرار الخدمة؛",
            "تقييم أداء التطبيق لتحسين تجربة المستخدم."
          ]
        },
        {
          title: "3. مشاركة المعلومات",
          content: "نحن لا نشارك معلوماتك مع أي طرف ثالث."
        },
        {
          title: "4. التغييرات في سياسة الخصوصية",
          content: "قد نقوم بتحديث سياسة الخصوصية هذه من وقت لآخر لتعكس التغييرات في خدماتنا وممارسات الخصوصية. سيتم نشر أي تغييرات على هذه الصفحة، وستدخل النسخة الجديدة حيز التنفيذ على الفور."
        }
      ],
      conclusion: "نحن نقدر خصوصيتك ونقدر ثقتك ودعمك.",
      backToHome: "العودة إلى الصفحة الرئيسية"
    }
  };

  const currentLang = getBrowserLanguage();
  const defaultContent = {
    sections: [],
    conclusion: "",
    backToHome: "Back"
  };

  const t = { ...defaultContent, ...(content[currentLang] || content.en) };

  return (
    <div className="privacy-container">
      <div className="privacy-content">
        <h1>{t.title}</h1>
        <p className="intro">{t.intro}</p>

        {t.sections && t.sections.map((section, index) => (
          <section key={index}>
            <h2>{section.title}</h2>
            {section.content && <p>{section.content}</p>}
            {section.items && (
              <ul>
                {section.items.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            )}
            {section.additional && <p>{section.additional}</p>}
          </section>
        ))}

        {t.conclusion && <p className="conclusion">{t.conclusion}</p>}

        <div className="back-to-home">
          <Link to="/" className="back-link">{t.backToHome}</Link>
        </div>
      </div>
    </div>
  );
};

export default Privacy; 